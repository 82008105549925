body {
  font-family: sans-serif;
  margin: 0;
  /* overflow: hidden; */
  overflow-x: hidden;
  background-color: #000;
  height: 1000vh;
}

#container {
  position: fixed;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1;
}

.dg.ac {
  z-index: 10 !important;
}
